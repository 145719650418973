/**
 * `Preview` is an interstitial page component that displays while preview data
 * is fetched from Prismic. When the data is loaded, the component navigates
 * to the appropriate page based on the path mappings in `linkResolver`.
 *
 */

import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useAppState } from "@state/appState";
import { usePrismicPreview } from "gatsby-source-prismic";
import { linkResolver } from "@dataResolvers";
import { Text } from "@base";

/* eslint react/prop-types: 0 */

const Preview = () => {
  const [, dispatch] = useAppState();

  const { isPreview, previewData, path } = usePrismicPreview({
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
  });

  useEffect(() => {
    if (isPreview) {
      dispatch({
        type: "layout.setPreviewMode",
        previewMode: true,
      });

      window.__PRISMIC_PREVIEW_DATA__ = previewData;

      navigate(path);
    }
  }, [isPreview, previewData, path]);

  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-black z-50">
      <Text tag="p" className="text-white">
        Loading preview...
      </Text>
    </div>
  );
};

export default Preview;
